import Lines from "./lines"
import Dots from "./dots"
import Smoke from "./smoke"
import Mountains from "./mountains"
import NoisyDots from "./noisy-dots";
import NoisyLines from "./noisy-lines";
import Road from "./road";
import Hash from "./hash";
import Retro from "./retro";
import Volcano from "./volcano"
export default {
    // Volcano,
    Lines,
    Dots,
    Smoke, 
    Mountains,
    NoisyDots,
    NoisyLines,
    // Road,
    Hash,
    Retro,
}